// Customizing the axios api calls
import axios from 'axios';
import store from '../store/index';

const Api = axios.create({
    baseURL: process.env.VUE_APP_baseUrl
    //timeout:5000, //5 seconds
});

Api.interceptors.request.use(
    function (config) {
        const token = store.getters['workdayAuth/getToken'];
        //for every request add the token to the authorization header
        config.headers.Authorization = `${token}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default Api;
