import Vue from "vue";
import Router from "vue-router";
import CheckoutComponent from "@/components/checkout.vue";
import SuccessComponent from "@/components/success.vue";
import CancelComponent from "@/components/cancel.vue";
import Terms from "@/views/TermsView.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "",
      redirect: () => {
        window.location.href = process.env.VUE_APP_WorkdayHomeUri;
        return ""; // not important since redirecting
      },
    },
    {
      name: "home",
      path: "/pay",
      component: CheckoutComponent,
    },
    {
      path: "/success",
      component: SuccessComponent,
    },
    {
      path: "/cancel",
      component: CancelComponent,
    },
    {
      path: "/terms",
      component: Terms,
    },
  ],
});
