import Api from './Api';
const endPoint = 'payment';

const getAccessToken = code => Api.get(`${endPoint}/gettoken`, {
    params: {
        code: code
    }
});
const createCheckoutSession = sessionRequest => Api.post(`${endPoint}/createcheckoutsession`, sessionRequest);
const updatePaymentStatus = paymentStatus => Api.post(`${endPoint}/updatepaymentstatus`, paymentStatus);

export {
    getAccessToken,
    createCheckoutSession,
    updatePaymentStatus
};