import Vue from 'vue';
import Vuex from 'vuex';
import WorkdayAuthModule from './modules/workdayauth.module';
import PaymentModule from './modules/payment.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        workdayAuth: WorkdayAuthModule,
        payment: PaymentModule,
    }
});
