<template>
    <div class="container">
        <div class="success">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" color="green" class="bi bi-check-circle"
                 viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
            Payment successful
            <div>
                <small class="help-text">Please wait while we redirect you back to workday</small>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        async created() {
            const workdayInstanceId = this.$route.query.wsid;
            const stripeSessionId = this.$route.query.sid;
            const paymentStatusRequest = {
                workdaySessionId: workdayInstanceId,
                stripeCheckoutSessionId: stripeSessionId,
            }
            this.$router.replace('/success');
            try {
                await this.$store.dispatch('payment/updatePaymentStatus', paymentStatusRequest);
                await this.$store.dispatch('workdayAuth/removeAccessToken');
                window.location = `${process.env.VUE_APP_WorkdayPaymentStatusUri}/${workdayInstanceId}/${workdayInstanceId}.htmld`
            } catch (error) {
                console.log(error.response.data);
                if (error.response && error.response.data) {
                    console.log(error.response.data);
                    let self = this;
                    this.$swal({
                        title: 'Error!',
                        text: error.response.data,
                        icon: 'error',
                    }).then(async () => {
                        await self.$store.dispatch('workdayAuth/removeAccessToken');
                        window.location = `${process.env.VUE_APP_WorkdayPaymentStatusUri}/${workdayInstanceId}/${workdayInstanceId}.htmld`
                    })
                }
            } finally {
                self.loading = false;
            }
        },
    };
</script>
<style scoped>
    .success {
        font-size: 40px;
        margin-top: 5em;
    }

    .container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .help-text {
        font-size: 15px;
    }
</style>