<template>
  <div class="checkout-container">
    <b-modal
      id="termsandconditionsmodal"
      size="xl"
      centered
      scrollable
      ok-title="Agree"
      ok-variant="success"
      cancel-title="Do not Agree"
      cancel-variant="danger"
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      @ok="agreetc"
      @cancel="donotagreetc"
    >
      <Terms />
    </b-modal>
    <div v-if="loading">
      <LoadingArete />
    </div>
    <section>
      <h1>Payment Summary</h1>
      <table width="50%" id="summary">
        <!--<tr>
                    <td width="20%"><div class="label">Company</div></td>
                    <td>{{invoiceDetails.company}}</td>
                </tr>
                <tr>
                    <td width="20%"><div class="label">Customer</div></td>
                    <td>{{invoiceDetails.customer}}</td>
                </tr>-->
        <tr>
          <td width="20%"><div class="label">Payment Date</div></td>
          <td>{{ new Date() | formatDate }}</td>
        </tr>
        <tr>
          <td width="20%"><div class="label">Currency</div></td>
          <td>{{ invoiceDetails.currency }}</td>
        </tr>
        <tr>
          <td width="20%"><div class="label">Payment Total</div></td>
          <td>{{ invoiceDetails.formattedPayment }}</td>
        </tr>
      </table>
    </section>
    <section>
      <h2>Invoices to Pay</h2>
      <div>
        <table id="invoices">
          <tr>
            <th>Invoice Number</th>
            <th>PO Number</th>
            <th>Invoice Date</th>
            <th>Due Date</th>
            <th>Currency</th>
            <th>Total Invoice Amount</th>
            <th>Amount Due</th>
            <th>Amount To Pay</th>
          </tr>
          <tr v-for="(invoice, index) in invoiceDetails.invoices" :key="index">
            <td>{{ invoice.invoiceNumber }}</td>
            <td>{{ invoice.poNumber }}</td>
            <td>{{ invoice.invoiceDate | formatISODate }}</td>
            <td>{{ invoice.dueDate | formatISODate }}</td>
            <td>{{ invoice.currency }}</td>
            <td>{{ invoice.formattedTotalInvoiceAmount }}</td>
            <td>{{ invoice.formattedAmountDue }}</td>
            <td>{{ invoice.formattedAmountToPay }}</td>
          </tr>
        </table>
      </div>
    </section>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :session-id="sessionId"
      @loading="(v) => (loading = v)"
    />
    <div class="payBtnWrapper">
      <div v-if="!isError" style="display: inline-flex">
        <button class="payBtn" @click="submit">Pay now</button>
      </div>
      <div style="display: inline-flex; margin-left: 1em">
        <button class="cancelBtn" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import Terms from "@/components/terms";
export default {
  props: ["state"],
  components: {
    StripeCheckout,
    Terms,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      sessionId: null,
      invoiceDetails: {
        invoices: [],
      },
      workdayInstanceId: null,
      isError: false,
    };
  },
  async mounted() {
    if (!localStorage.getItem("areTermsAgreed")) {
      this.$bvModal.show("termsandconditionsmodal");
    } else {
      await this.performCheckout();
    }
  },
  async created() {},
  methods: {
    async performCheckout() {
      try {
        this.loading = true;
        this.workdayInstanceId = this.$route.query.state;
        const sessionRequest = {
          WSessionId: this.workdayInstanceId,
        };
        this.$router.replace("/pay");
        if (this.workdayInstanceId) {
          try {
            const sessionAndInvoiceDetails = await this.$store.dispatch(
              "payment/createCheckoutSession",
              sessionRequest
            );
            this.sessionId = sessionAndInvoiceDetails.id;
            this.invoiceDetails = sessionAndInvoiceDetails.invoiceDetails;
          } catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
              console.log(error.response.data);
              let self = this;
              this.$swal({
                title: "Error!",
                text: error.response.data,
                icon: "error",
              }).then(async () => {
                await self.$store.dispatch("workdayAuth/removeAccessToken");
                window.location = process.env.VUE_APP_WorkdayHomeUri;
              });
            }
          }
        }
      } catch (error) {
        this.isError = true;
        console.log(error);
        if (error.response && error.response.data) {
          console.log(error.response.data);
          let self = this;
          this.$swal({
            title: "Error!",
            text: error.response.data,
            icon: "error",
          }).then(async () => {
            await self.$store.dispatch("workdayAuth/removeAccessToken");
            window.location = process.env.VUE_APP_WorkdayHomeUri;
          });
        }
      } finally {
        this.loading = false;
      }
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    async agreetc() {
      localStorage.setItem("areTermsAgreed", true);
      await this.performCheckout();
    },
    async donotagreetc() {
      await this.cancel();
    },
    async cancel() {
      this.workdayInstanceId =
        this.workdayInstanceId || this.$route.query.state;
      if (this.workdayInstanceId) {
        this.loading = true;
        const paymentStatusRequest = {
          workdaySessionId: this.workdayInstanceId,
        };
        try {
          await this.$store.dispatch(
            "payment/updatePaymentStatus",
            paymentStatusRequest
          );
          await this.$store.dispatch("workdayAuth/removeAccessToken");
          window.location = `${process.env.VUE_APP_WorkdayPaymentStatusUri}/${this.workdayInstanceId}/${this.workdayInstanceId}.htmld`;
        } catch (error) {
          console.log(error);
          if (error.response && error.response.data) {
            console.log(error.response.data);
            let self = this;
            this.$swal({
              title: "Error!",
              text: error.response.data,
              icon: "error",
            }).then(async () => {
              await self.$store.dispatch("workdayAuth/removeAccessToken");
              window.location = `${process.env.VUE_APP_WorkdayPaymentStatusUri}/${this.workdayInstanceId}/${this.workdayInstanceId}.htmld`;
            });
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.checkout-container {
  width: 95%;
  margin: 30px auto;
}
th {
  font-size: 15px;
}

#invoices {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#invoices td,
#invoices th {
  border: 1px solid #ddd;
  padding: 8px;
}

#invoices tr:nth-child(even) {
  background-color: #f2f2f2;
}

#invoices tr:hover {
  background-color: #ddd;
}

#invoices th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.payBtnWrapper {
  margin-top: 2em;
  float: right;
}

.payBtn {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.payBtn:disabled {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: not-allowed;
}

.cancelBtn {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.label {
  font-weight: 600;
  font-size: 16px;
}

#summary {
  border-collapse: separate;
  border-spacing: 0 1em;
}
</style>