<template>
  <div class="payment-portal-footer">
    <p>&copy; 2021 AreteIR</p>
    <router-link to="/terms" target="_blank"> Terms & Conditions </router-link>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style lang="scss">
.payment-portal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 5px;
  right: 5px;
  background: #11588d;
  padding: 2rem;
  gap: 1rem;
  a {
    color: white !important;
    text-decoration: none !important;
    font-size: 1.2rem;
    font-weight: bold;
  }
  p {
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
    margin-bottom: 0px !important;
  }
}
</style>
