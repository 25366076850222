<template>
  <div class="tncontainer">
    <Terms />
  </div>
</template>
<script>
import Terms from "@/components/terms";
export default {
  name: "TermsView",
  components: { Terms },
};
</script>
<style scoped lang="scss">
.tncontainer {
  display: flex;
  justify-content: center;
}
</style>