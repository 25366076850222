<template>
    <div></div>
</template>
<script>
    export default {
        async created() {
            try {
                const workdayInstanceId = this.$route.query.wsid;
                await this.$store.dispatch('payment/removeAccessToken');
                this.$router.replace(`/pay?state=${workdayInstanceId}`);
            } catch (error) {
                console.log(error);
            }
        },
    };
</script>