<template>
  <div>
    <AppTopBar class="layout-topbar" />
    <router-view v-if="token != null" />
    <template v-if="$route.path !== '/terms'"> <AppFooter /></template>
  </div>
</template>
<script>
import AppTopBar from "@/components/layout/AppTopbar.vue";
import AppFooter from "@/components/layout/AppFooter.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      token: "workdayAuth/getToken",
    }),
  },
  async created() {
    if (this.$route.path == "/pay") {
      const sessionId = this.$route.query.sessionId;
      const code = this.$route.query.code;
      if (code == null) {
        window.location =
          process.env.VUE_APP_AuthEndpoint + "&state=" + sessionId;
      } else {
        if (code != null) {
          try {
            await this.$store.dispatch("workdayAuth/getAccessToken", code);
          } catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
              console.log(error.response.data);
              this.$swal({
                title: "Error!",
                text: error.response.data,
                icon: "error",
              }).then(() => {
                window.location = process.env.VUE_APP_WorkdayHomeUri;
              });
            }
          }
        }
      }
    }
  },
  async mounted() {
    this.setSessionTimeout();
  },
  methods: {
    setSessionTimeout() {
      let self = this;
      //warning timeout
      console.log(process.env.VUE_APP_SessionTimeoutWarning);
      setTimeout(() => {
        self
          .$swal({
            title: "Warning!",
            text: `Your session will expire in ${
              (parseInt(process.env.VUE_APP_SessionTimeout, 10) -
                parseInt(process.env.VUE_APP_SessionTimeoutWarning, 10)) /
              60
            } minutes.`,
            icon: "warning",
          })
          .then(() => {});
      }, parseInt(process.env.VUE_APP_SessionTimeoutWarning, 10) * 1000);

      //actual session timeout
      setTimeout(async () => {
        await self.$store.dispatch("workdayAuth/removeAccessToken");
        window.location = process.env.VUE_APP_WorkdayHomeUri;
      }, parseInt(process.env.VUE_APP_SessionTimeout, 10) * 1000);
    },
  },
  components: {
    AppTopBar: AppTopBar,
    AppFooter,
  },
};
</script>

