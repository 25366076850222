<template>
  <div class="termsnconditions">
    <h3><u>ARETE ADVISORS, LLC PAYMENT PORTAL TERMS & CONDITIONS</u></h3>
    <p>
      The Payment Portal Terms & Conditions (the “Terms”) constitute a contract
      between you and Arete Advisors, LLC and its affiliated companies
      (collectively, “Arete”). Please read them carefully.
    </p>
    <p>
      By use of this Payment Portal (the “Portal”), you agree to abide by the
      published Terms as listed on this page. Arete may, at its sole discretion,
      update, modify, change, add to, or remove the Terms at any time and with
      or without notice. Arete many also change, terminate, suspend or
      discontinue any aspect of the Portal, including the availability of any
      features on the Portal or your access thereto with or without notice. If
      you do not agree with any of these Terms, please do not use or access this
      website.
    </p>
    <p>
      All payments of Arete invoices using the Portal are subject to the
      following conditions. You represent and warrant that:
    </p>
    <p>
        <ul>
            <li>You are 18 years of age or older.</li>
            <li>You have the appropriate authority to validly accept these Terms and can and will meet your obligations in relation to these Terms.</li>
            <li>The credit card used in connection with payment through this Portal is issued in your name or you are authorized to use the credit card.</li>
            <li>You will pay the credit card issuer all charges incurred in the use of this Portal.</li>
            <li>The information supplied by you is true and correct.</li>
            <li>You will not violate the security of the Portal nor attempt to gain unauthorized access to the Portal, data, materials, information, computer systems or networks connected to any server associated with the Portal, through hacking, password timing or any other means.</li>
            <li>You will not take nor attempt any action that, in the sole discretion of Arete, imposes or may impose an unreasonable or disproportionately large burden on the Portal or its infrastructure.</li>
            <li>You will not use nor attempt to use any scraper, robot, bot, spider, data mining, computer code, or any other automated device, program, tool, algorithm, process or methodology to access, index, acquire, copy, or monitor any portion of the Portal, any data or content found on or accessed through the Portal without the prior written consent of Arete.</li>
            <li>You will not forge headers or otherwise manipulate identifiers to disguise the origin of any other content.</li>
        </ul>
    </p>
    <h3>Authentication and Authorization for Portal Usage:</h3>
    <p>
        <ul>
            <li>This Portal incorporates commercially reasonable methods to authenticate the information that you have supplied for making a payment through this Portal. You will not be able to submit a payment until the Portal authenticates you and you will not be allowed to use the Portal to pay your invoice until you have read these Terms and Conditions and have acknowledged doing so by selecting "I agree to the Terms and Conditions."</li>
            <li>As the user of the Service ("User"), and upon your acceptance of these Terms and Conditions, you will have submitted your credit card information. You are responsible for any legal, regulatory, or banking penalties and fees that may be assessed for supplying false information to us for use with the Service.</li>
            <li>The Portal is used for the payment of Arete invoices with a credit card.  By accepting these Terms, you authorize Arete to initiate a charge to your designated credit card according to your instructions. Each time you use the Portal to pay an invoice, you will be reauthorizing Arete to charge your designated credit card.</li>
        </ul>
    </p>
    <h3>Security:</h3>
    <p>
        All payment details which are entered through this payment gateway are encrypted when the User enters them. The site is secure and uses 256-bit encryption to offer secure communications by encrypting all data to and from the site.  Arete shall not be liable for any failure by the User making payment to properly protect data from being seen on their screen by other persons or otherwise obtained by such other persons, during the Portal payment process or in respect of any omission to provide accurate information during the Portal payment process
    </p>
    <h3>Disclaimer of Warranties: </h3>
    <p>ARETE IS PROVIDING THE SERVICE “AS IS” WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. ARETE ADVISORS, LLC DOES NOT WARRANT THAT THE SERVICE IS ERROR-FREE, OR THAT ACCESS TO AND USE OF THE SERVICE WILL BE AVAILABLE OR UNINTERRUPTED.</p>
  <h3>Limitations of Liability: </h3>
  <p>In no event will Arete or any of its representatives or affiliates be liable for: (a) damages of any kind, including without limitation, direct, incidental or consequential damages (including, but not limited to, damages for lost profits, business interruption and loss of programs or information) arising out of the use of or inability to use this Portal, or any information provided on this Portal, or (b) any claim attributable to errors, omissions or other inaccuracies in the information provided on this Portal. </p>
  <p>Additionally, Arete expressly disclaims any and all liability for the collection and handling of cardholder data. You agree that transmitting cardholder data on the Internet may involve certain security risks and Arete shall not be responsible for any damages or loss caused, or alleged to be caused, or alleged to be caused, as a result of the transmission of the cardholder data prior to its encryption and receipt by Arete’s server(s), including but not limited to damages, fraud, embezzlement, theft, identity theft or invasion of privacy. </p>
  <h3>Indemnification:</h3>
  <p>You agree to indemnify, defend and hold harmless Arete, its affiliates, licensors, and their respective officers, directors, employees and agents from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of your use/ misuse of this Portal.</p>
  <h3>Third-Party Rights:</h3>
    <p>The terms hereunder regarding Disclaimer of Warranties, Limitation of Liability and Indemnification are for the benefit of Arete, and its licensors, employees and agents, each of whom shall have the right to assert and enforce the terms under this Agreement.  Such terms shall also survive termination.</p>
  <h3>Assignment:</h3>
  <p>You may not assign this Agreement to any other party. Arete may assign this Agreement to any directly or indirectly affiliated company. Arete may also assign or delegate certain of its rights and responsibilities under this Agreement to independent contractors or other third parties.</p>
  <h3>General:</h3>
  <p>This Agreement is governed and shall be construed in accordance with the laws of the State of Florida, excluding its choice of law rules. This Agreement constitutes the entire agreement and supersedes any prior agreements or understandings, oral or written, between Arete and you concerning the Portal and may only be amended as provided herein. Failure or delay in enforcing any right or provision of this Agreement shall not be deemed a waiver of such provision or right with respect to any subsequent breach or a continuance of an existing breach. If any provision of this Agreement shall be held to be unenforceable that provision will be enforced to the maximum extent possible, and the remaining provisions of this Agreement will remain in full force and effect.</p>
  </div>
</template>
<script>
export default {
  name: "Terms",
};
</script>
<style scoped lang="scss">
.termsnconditions {
  width: 80%;
  margin: 30px auto 30px auto;
}
</style>