import { getAccessToken } from '../../services/paymentService';

const state = {
    token: localStorage.getItem('wstoken') || null,
}

const getters = {
    getToken: state => {
        return state.token;
    },
}

const actions = {
    // eslint-disable-next-line no-unused-vars
    async getAccessToken({ commit }, code) {
        const response = await getAccessToken(code);
        const accessToken = response.data.data;
        commit('setToken', accessToken);
        localStorage.setItem('wstoken', accessToken);
        return accessToken;
    },
    // eslint-disable-next-line no-unused-vars
    async removeAccessToken({ commit }) {
        commit('setToken', null);
        localStorage.removeItem('wstoken');
    },
}

const mutations = {
    setToken(state, token) {
        state.token = token;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}