import { /*getInvoiceDetails,*/ createCheckoutSession, updatePaymentStatus } from '../../services/paymentService';

const state = {
}

const getters = {
}

const actions = {
    // eslint-disable-next-line no-unused-vars
    async createCheckoutSession({ commit }, sessionRequest) {
        const response = await createCheckoutSession(sessionRequest);
        return response.data.data;
    },
    // eslint-disable-next-line no-unused-vars
    async updatePaymentStatus({ commit }, paymentStatus) {
        const response = await updatePaymentStatus(paymentStatus);
        return response.data.data;
    },
}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}